<template>
  <div>
    <v-simple-table>
      <thead>
        <tr>
        </tr>
      </thead>
      <tbody>
        <td
          v-for="(item, key) in kit"
          :key="key"
          style="text-align:center; vertical-align:middle"
        >
          <v-container
            fill-height
            fluid
          >
            <div>
              <img
                :width="iconMapping[key].width"
                :height="iconMapping[key].height"
                :src="iconMapping[key].url"
                alt="calçoes"
              />
              <h4>
                {{ item }}
              </h4>
            </div>
          </v-container>
        </td>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
const iconMapping = {
  topwearSize: {
    url: 'https://img.icons8.com/ios/50/t-shirt--v1.png',
    width: 15,
    height: 15,
  },
  bottomWearSize: {
    url: 'https://img.icons8.com/ios/50/shorts.png',
    width: 15,
    height: 15,
  },
  details: {
    url: 'https://img.icons8.com/ios/50/star--v1.png',
    width: 15,
    height: 15,
  },
  jacketSize: {
    url: 'https://img.icons8.com/carbon-copy/100/tracksuit.png',
    width: 20,
    height: 20,
  },
  pantsSize: {
    url: 'https://img.icons8.com/dotty/80/trousers.png',
    width: 20,
    height: 20,

  },
  underlayer: {
    url: 'https://img.icons8.com/ios/50/t-shirt--v1.png',
    width: 15,
    height: 15,
  },
  bag: {
    url: 'https://img.icons8.com/external-outline-design-circle/66/external-Sports-Bag-sports-activities-outline-design-circle.png',
    width: 20,
    height: 20,
  },
}

export default {
  props: {
    kit: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      iconMapping,
    }
  },
}
</script>
