<script>
import OrderStatus from '@/sharedcomponents/OrderStatus.vue'
import KitTable from './KitTable.vue'

const paymentColorMapping = {
  true: 'green',
  false: 'orange',
}

const paymentTitle = {
  true: 'Pago',
  false: 'Falta Pagamento',
}
const headers = ['Status', 'Preço', 'Equipamento Principal', 'Equipamento Secundario', 'Equipamento Treino', 'Fato de Treino', 'Saco/Mochila', 'Obs']
const orderStatusMappins = {
  to_deliver: 'Para Entrega',
  delivered: 'Entregue',
  registered: 'Pedido Efectuado',
  ordered: 'Encomendado',
}

export default {
  components: {
    KitTable,
    OrderStatus,
  },
  props: {
    // headers: {
    //   type: Array,
    //   default: headers,
    // },
    kitData: {
      type: Array,
      required: true,
    },
  },
  setup() {
    return {
      headers,
      orderStatusMappins,
      paymentColorMapping,
      paymentTitle,
    }
  },
}
</script>

<template>
  <div>
    <VRow>
      <v-simple-table
        :headers="headers"
        item-key="fullName"
        class="table-rounded"
        hide-default-footer
        disable-sort
      >
        <thead>
          <tr>
            <th
              v-for="header in headers"
              :id="header"
              :key="header"
              style="text-align:center; vertical-align:middle"
            >
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="kit in kitData"
            :id="kit"
            :key="kit"
          >
            <td>
              <order-status
                :title="orderStatusMappins[kit.orderStatus]"
                :success="orderStatusMappins['delivered']"
                :warning="orderStatusMappins['to_deliver']"
              />
            </td>
            <td>
              <span>
                {{ kit.price }} €
              </span>
              <br />
              <span
                :style="'color:'+paymentColorMapping[kit.isPaid]"
              >
                <!-- {{ paymentTitle[kit.isPaid] }} -->
              </span>
            </td>
            <td>
              <kit-table
                :kit="kit.mainKit"
              />
            </td>
            <td>
              <kit-table
                :kit="kit.alternativeKit"
              />
            </td>
            <td>
              <kit-table
                :kit="kit.trainingKit"
              />
            </td>
            <td>
              <kit-table
                :kit="kit.trackSuit"
              />
            </td>
            <td>
              <kit-table
                :kit="kit.bag"
              />
            </td>
            <td>
              {{ kit.kitName }} - {{ kit.kitNumber }}
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </VRow>
  </div>
</template>
