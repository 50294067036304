<script>
import { inject, ref } from '@vue/composition-api'
import PlayerKitTable from './PlayerKitTable.vue'

const tokenUtils = ref({})
const personService = ref({})
const personDetails = ref({})
const apiService = ref({})
const ordersLegacyService = ref({})
const playerService = ref({})
const filtersService = ref({})
const asyncDataLoader = ref(false)

const personId = ref(-1)
const cognitoForms = ref('')
const userRoles = ref([])
const kitData = ref([])
const clubLevels = ref([])
const playersOrders = ref([])
let selectedClubLevel = null

export default {
  components: {
    PlayerKitTable,
  },
  async created() {
    const entry = {}
    const token = tokenUtils.value.GetToken(this.$cookies)

    personId.value = tokenUtils.value.GetClaim(token, 'person_id')
    userRoles.value = tokenUtils.value.GetClaim(token, 'roles')
    ordersLegacyService.value.get(personId.value)
      .then(result => {
        kitData.value = result
      })
    const personScope = await personService.value.getScopeDetails(personId.value)
    let playerId = null

    if (personScope != null) {
      if (personScope.player != null) {
        playerId = personScope.player.id
      }
    }

    filtersService.value.get()
      .then(response => {
        clubLevels.value = response.ClubLevels
      })

    await Promise.all(
      [
        personService.value.get(personId.value)
          .then(result => {
            personDetails.value = result

            entry.UserId = String(personDetails.value.id)
            entry.NomeCompleto = personDetails.value.fullName
          }),
        playerService.value.get(playerId)
          .then(playerResult => {
            if (playerResult != null) {
              if (playerResult.length > 0) {
                entry.Escalao = playerResult[0].clubLevel
              }
            }
          }),
      ],
    )

    cognitoForms.value = `https://www.cognitoforms.com/Footback1/EquipamentosEquipas?entry=${JSON.stringify(entry)}`
    asyncDataLoader.value = true
  },
  methods: {
    changeClubeLevel(selectedValue) {
      selectedClubLevel = selectedValue
      this.getPlayersOrders(selectedClubLevel)
    },
    async getPlayersOrders(clubLevel) {
      playersOrders.value = await ordersLegacyService.value.getClubLevelOrders(0, clubLevel)
    },
  },
  setup() {
    tokenUtils.value = inject('tokenutils')
    personService.value = inject('userservice')
    ordersLegacyService.value = inject('orderslegacyservice')
    playerService.value = inject('playerservice')
    apiService.value = inject('apiservice')
    filtersService.value = inject('filtersservice')

    return {
      cognitoForms,
      userRoles,
      asyncDataLoader,
      kitData,
      clubLevels,
      playersOrders,
    }
  },
}
</script>

<template>
  <div>
    <div>
      <div>
        <VRow class="match-height">
          <VCol cols="12">
            <v-btn
              v-if="asyncDataLoader"
              color="secondary"
              :href="''+cognitoForms"
              target="_blank"
            >
              Pedido de Kit
            </v-btn>
          </VCol>
        </VRow>
      </div>
      <div style="margin-bottom: 2em;"></div>
      <h3>Encomendas Pessoais</h3>
      <div style="margin-bottom: 1em;"></div>
      <player-kit-table
        :kit-data="kitData"
      ></player-kit-table>
    </div>
    <div style="margin-bottom: 2em;"></div>
    <div
      v-if="userRoles.indexOf('Admin') > -1 ||
        userRoles.indexOf('ClubManagement') > -1"
    >
      <h3>Encomendas Jogadores</h3>
      <v-select
        style="width: 10em;"
        :items="clubLevels"
        label="Escalões"
        @change="changeClubeLevel"
      />
      <player-kit-table
        :kit-data="playersOrders"
      />
    </div>
  </div>
</template>
