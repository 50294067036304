<script>

const statusColor = {
  Delivered: 'success',
  WaitingPickUp: 'error',
  WaitingOrder: 'warning',
}

export default {
  components: {
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    success: {
      type: String,
      required: true,
    },
    warning: {
      type: String,
      required: true,
    },
    error: {
      type: String,
      required: true,
    },
  },
  methods: {
    getColor() {
      if (this.title === this.success) {
        return 'success'
      }
      if (this.title === this.warning) {
        return 'warning'
      }

      if (this.title === this.error) {
        return 'error'
      }

      return ''
    },
  },
  setup() {
    return {
      statusColor,
    }
  },
}

</script>

<template>
  <div>
    <VChip
      small
      :color="getColor()"
      class="font-weight-medium"
    >
      {{ title }}
    </VChip>
  </div>
</template>
